import React, { Component } from "react"
import { Link } from "gatsby"
import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import HomeBody from "../../components/home/HomeBody"
import Footer from "../../components/footer/Footer"
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/donate.css"
import ReactModal from "react-modal"
import Img from "gatsby-image"
import iconImage from "../../../static/images/donate/icon.jpg" // Tell Webpack this JS file uses this image
import headerImage from "../../../static/images/donate/header.png" // Tell Webpack this JS file uses this image

class Donate extends Component {
	constructor(props){
		super(props)
		this.inputs = []
		this.totalFields = 20
		let visibleInputs = Array(this.totalFields).fill(false)
		visibleInputs[0] = true

		let disabledInputs = Array(this.totalFields).fill("disabled")
		disabledInputs[0] = ''

		let showRecurCatagories = Array(9).fill(false)
		showRecurCatagories[0]=true

		this.state= {
			index:0,
			visibleInputs: visibleInputs,
			disabledInputs:disabledInputs,
			recuringPayment:false,
			recuringOptionsDisabled:Array(9).fill(true),
			recuringSavedState:Array(9).fill(true),
			saleAmounts: Array(this.totalFields).fill(0.0),
			modalOpen:false,
			recuringText:" original",
			categories:Array(20).fill(null),
			otherCategories:Array(20).fill(null),
			otherCategoryInputVisible:Array(20).fill(false),
			showRecurCatagories: showRecurCatagories
		}


	}
	getInputs = (key) => {
		const clazzName="donate-input-key-"+key
		const nextId = this.state.disabledInputs[key] ? 'hidden-next':'show-next'
		return (
			
			
				<tr key={key} className={clazzName} id={this.state.visibleInputs[key] ? '':'hidden-input'}>
					<td>
						
							<select className="donate-category" onChange={(event) => 
										this.updateSelectCategory(event.target.value,key)} 
										disabled={this.state.disabledInputs[key]}>
								<option value="none">Choose</option>
								<option value="Tithe">Tithe</option> 
								<option value="PFD">PFD Tithe</option>
								<option value="St. James House">St. James House</option>
								<option value="Sisters of Mary &amp; Martha">Sisters of Mary &amp; Martha</option>
								<option value="St. John School">St. John School</option>
								<option value="Eagle River Institute">Eagle River Institute</option>
								<option value="General Donation">General Donation</option>
								<option value="Family Night">Family Night Dinner</option>
								<option value="Soup Supper">Lenten Soup Super</option>
								<option value="Pascha Feast">Pascha Feast</option>
								<option value="">Other</option>
							</select>
						    <div>
						    	<span className={this.state.otherCategoryInputVisible[key] ? '':'hidden-input'}>
						    		<input 
							    		type="text" 
							    		className="other-category" 
							    		 
							    		placeholder="Donation Category" 
							    		disabled={this.state.disabledInputs[key]}
							    		onChange={(event) => 
											this.updateOtherCategory(event.target.value,key)}
						    		/>
						    		<input 
							    		type="hidden" 
							    		className="other-category" 
							    		name="ItemDesc" 
							    		value={this.state.categories[key] == null ? '':this.state.categories[key]} 
							    		placeholder="Donation Category" 
							    		disabled={this.state.disabledInputs[key]}
						    		/>
						    	</span>
						    </div>
					</td>
					<td align="right" className="donate-amount" valign="top">
						<input type="hidden" name="ItemQty" value="1" disabled={this.state.disabledInputs[key]}/>
						<input type="text" className="donate-amount-input" name="ItemCost" size="8" maxLength="8" onChange={(event) => this.setSaleAmount(key,event.target.value)} disabled={this.state.disabledInputs[key]} />
					</td>
					<td align="right" className="cancel-button" onClick={ (event) => this.changeStateForKey(key)}>cancel</td>
				</tr>
						

			)
	}
	updateSelectCategory = (value, key) => {
		this.state.otherCategoryInputVisible[key] = value === '' ? true : false
		this.setState({otherCategoryInputVisible:this.state.otherCategoryInputVisible})

		value = value !== '' ? value:this.state.otherCategories[key]

		let showRecurCatagories = Array(8).fill(false)
		showRecurCatagories[0] = true

		if(value === 'Tithe'){
			showRecurCatagories[1] = true
			showRecurCatagories[2] = true
			showRecurCatagories[3] = true
			showRecurCatagories[4] = true
		} else if(value == 'St. James House'){
			showRecurCatagories[5] = true
			showRecurCatagories[6] = true
		} else if(value == 'St. John School'){
			showRecurCatagories[7] = true
			showRecurCatagories[8] = true
		}

		this.setState({showRecurCatagories:showRecurCatagories})
		this.updateCategory(value,key)

	}
	updateOtherCategory = (value, key) => {
		this.state.otherCategories[key] = value
		this.setState({otherCategories:this.state.otherCategories})
		this.updateCategory(value,key)

	}
	updateCategory = (value, key) => {
		this.state.categories[key] = value
		this.setState({categories:this.state.categories})

	}
	resetPage = () => {
		let visibleInputs = Array(10).fill(false)
		visibleInputs[0] = true

		let disabledInputs = Array(10).fill("disabled")
		disabledInputs[0] = ''
		this.setState({
			index:0,
			visibleInputs: visibleInputs,
			disabledInputs:disabledInputs
		})


	}

	removeRecurringDiv = () => {

	}

	addInput = () => {
		this.state.index++;
		this.changeStateForKey(this.state.index)
		this.showRecurring(false)
	}

	changeStateForKey = (key) => {
		this.setSaleAmount(key,0) //If a category gets added or removed the sale amount is set as 0
		this.state.visibleInputs[key] = !this.state.visibleInputs[key]
		this.state.disabledInputs[key] = this.state.disabledInputs[key] === 'disabled' ? '' : 'disabled'
		this.setState({visibleInputs:this.state.visibleInputs})
		this.setState({disabledInputs: this.state.disabledInputs})
	}

	showRecurring = (value) => {
		this.setState({recuringPayment: value})
		if(value === false){
			this.setState({recuringSavedState:this.state.recuringOptionsDisabled})
			this.setState({recuringOptionsDisabled:Array(9).fill(true)})
		} else {
			this.setState({recuringOptionsDisabled:this.state.recuringSavedState})
		}
	}

	setRecurAmount = (recurKey, recurText) => {
		let recuringOptionsDisabled = Array(9).fill(true)
		recuringOptionsDisabled[recurKey] = false
		this.setState(
			{
				recuringOptionsDisabled:recuringOptionsDisabled,
				recurText:recurText
			}
		)
	}
	setSaleAmount = (key, value) => {
		let saleAmounts = this.state.saleAmounts
		saleAmounts[key] = value
		this.setState({saleAmounts:saleAmounts})
		console.log(typeof this.state.saleAmounts[0])
		const total = this.state.saleAmounts.reduce((a,b) => parseFloat(a)+parseFloat(b))
		console.log("sale total: %s", total)
	}

	handleModalClose = event => {
    	this.setState({ 
      		modalOpen: false,
  		})
    }

    startSubmit = (event) => {
    	console.log(this.state)
    	if(this.state.recuringPayment){
    		event.preventDefault()
    		this.setState({modalOpen:true})
    	}

    }


	render () {
		this.inputs = Array.from(Array(this.totalFields).keys()).map(x => this.getInputs(x))

		const headerImageUrl = "https://www.stjohnalaska.org"+headerImage


		return (
			<div className="site body">
				<HeaderMinimal />
				<MainMenu/>
				<div className="line-across menu-line-across"></div>
				<main className="site-content donate">
					<div className="donate-image-wrapper">
						<img src={iconImage} className="donate-image" alt="Logo" />
					</div>
					<div className="donate-block">
					   <h4 className="block-header">Donate</h4>				


						 <form id="donate-form" className="donate-form" action="https://www.eprocessingnetwork.com/cgi-bin/wo/order.pl" method="post" name="donationform">
							<div className="PARAGRAPH_TEXT">
							To make a donation to St. John Orthodox Cathedral, please enter the category and the amount below. Donations can be made using Visa, Mastercard or American Express.
							<h5>
								Thank you for your contribution.
							</h5>
							</div>
							<div>
							<table border="0" cellPadding="5" cellSpacing="5" >
							<tbody>
							<tr className="table-headers">
								<td className="category-header">Category</td>
								<td className="amount-header">Amount</td>
								<td></td>
							</tr>
							{this.inputs}
							<tr className="add-category" onClick={ (event) => this.addInput(event)}>
								<td className="add-category" style={{
									    display: 'flex',
									    alignItems: 'center'
									}}>

									<span className={this.state.recuringPayment ? 'hidden':"add-category-div"}>Add Category </span>
									<AddCircleOutlineIcon style={{ fontSize: 13 }} id="add-donate-icon" className="material-ui-text"/>
								</td>
							</tr>
							
							</tbody>
							</table>

							<div className={(this.state.visibleInputs.filter(inp => inp==true).length != 1) || (['Tithe', 'St. James House','St. John School'].includes(this.state.categories[0]) == false) ? 'hidden':"recurring-wrapper"}>							
								<span className="recuring-input">
									<input className="double" type="checkbox" name="MakeRecurring" onClick={(event) => this.showRecurring(event.target.checked)} disabled={(this.state.visibleInputs.filter(inp => inp==true).length != 1) || (['Tithe', 'St. James House','St. John School'].includes(this.state.categories[0]) == false)}/>
									<label>Make This Payment Recurring</label>
								</span>

								<span className="recuring-options" align="right" >
									<select  
										onChange={(event) => 
										this.setRecurAmount(event.target.value,event.target.options[event.target.selectedIndex].text)} 
										disabled={!this.state.recuringPayment}>
										<option value="0">Choose How Often</option>
										<option value="2" className={this.state.showRecurCatagories[1] ? '':'hidden'} >
											Monthly, On The Fifth
										</option> 
										<option value="1" className={this.state.showRecurCatagories[2] ? '':'hidden'} >
											Monthly, On The First
										</option>
										<option value="3" className={this.state.showRecurCatagories[3] ? '':'hidden'} >
											Monthly, On This Day Of The Month
										</option>
										<option value="4" className={this.state.showRecurCatagories[4] ? '':'hidden'} >
											Weekly, On This Day Of The Week
										</option>
										<option value="5" className={this.state.showRecurCatagories[5] ? '':'hidden'} >
											Monthly, On the First
										</option>
										<option value="6" className={this.state.showRecurCatagories[6] ? '':'hidden'} >
											Monthly, On the Fifth
										</option>
										<option value="7" className={this.state.showRecurCatagories[7] ? '':'hidden'} >
											Monthly, On the First
										</option>
										<option value="8" className={this.state.showRecurCatagories[8] ? '':'hidden'} >
											Monthly, On the Fifth
										</option>
									</select>
								</span>
								<div className="cancel-existing-recur">
									<a href="https://forms.gle/dJaPYwq7FnMUdEvH6">Cancel existing recurring payment</a>
								</div>
							</div>
							<div className="form-buttons-wrapper">
								<div>
									<input type="submit" className="submit-modal-button" value="Continue" onClick={(event) => this.startSubmit(event)}/> 
									<input type="reset" className="reset-modal-button" value="Reset this Page" onClick={() => this.resetPage()}/>
								</div>
							</div>
							</div>
							<input type="hidden" name="QB" value=""/>
							<input type="hidden" name="ePNAccount" value="11131023"/>
							<input type="hidden" name="ReturnToURL" value="http://www.stjohnalaska.org"/>
							<input type="hidden" name="BackgroundColor" value="White"/>
							<input type="hidden" name="TextColor" value="Black"/>
							<input type="hidden" name="LogoURL" value={headerImageUrl}/>
							<input type="hidden" name="RecurAmountOverride" value={this.state.saleAmounts.reduce((a,b) =>  parseFloat(a)+parseFloat(b))} disabled={!this.state.recuringPayment}/>
							<input type="hidden" name="RecurringMethod" value="1" disabled={this.state.recuringOptionsDisabled[1]}/> 
							<input type="hidden" name="RecurringMethod" value="2" disabled={this.state.recuringOptionsDisabled[2]}/>
							<input type="hidden" name="RecurringMethod" value="3" disabled={this.state.recuringOptionsDisabled[3]}/>
							<input type="hidden" name="RecurringMethod" value="4" disabled={this.state.recuringOptionsDisabled[4]}/>
							<input type="hidden" name="RecurringMethod" value="5" disabled={this.state.recuringOptionsDisabled[5]}/>
							<input type="hidden" name="RecurringMethod" value="6" disabled={this.state.recuringOptionsDisabled[6]}/>
							<input type="hidden" name="RecurringMethod" value="7" disabled={this.state.recuringOptionsDisabled[7]}/>
							<input type="hidden" name="RecurringMethod" value="8" disabled={this.state.recuringOptionsDisabled[8]}/>

							</form>
					      </div>
				      </main>
						<ReactModal
		                isOpen={this.state.modalOpen}
		                onRequestClose={this.handleModalClose}
		                overlayClassName="donate-modal-overview"
		                className="donate-modal-content"
		                              >
		                <button className="close-modal-button"  onClick={() => this.handleModalClose()}>
		                X
		                </button>
		              <div className="donate-modal">
		      			<h3>Continue by confirming that this will be a recurring payment.</h3>
		                  <p className="modal-text">
		                  	Amount: ${this.state.saleAmounts.reduce((a,b) =>  parseFloat(a)+parseFloat(b))} 
		                  </p>
		                  <p className="modal-text">
		                  	When: {this.state.recurText}
		                  </p>
		                  <p className="modal-text">
		                  	Cancel or modify by sending an email to stjohnalaska@aol.com with your request
		                  </p>
		                  <div className='donate-modal-buttons'>
		                  	<input className="confirm-recur" type="submit" form="donate-form" value="Confirm"/> 
		                  	<input className="cancel-recur" type="button" form="donate-form" value="Go Back" onClick={() => this.handleModalClose()}/> 
		                  </div>
		                </div>
		              </ReactModal>
				<Footer />
			</div>
		)
	}
	
}
//							<input type="submit" value="Continue"/> 

//							<input type="hidden" name="RecurringMethod" value="1" disabled/> First Of The Month
//							<input type="hidden" name="RecurringMethod" value="2" disabled/> Fifth Of The Month
//							<input type="hidden" name="RecurringMethod" value="3" disabled/> This Day Each Month
//							<input type="hidden" name="RecurringMethod" value="4" disabled/> Weekly This Day Of the week
export default Donate 